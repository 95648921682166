

a{
    color: #2d0238;
}
.btn{
    box-shadow: none !important;
}
body{
    background-color: #fff !important;
}