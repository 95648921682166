

.carousel-container{
    width: 100%;
    height: 400px;
    position: relative;
    border: 1px solid  #cc1313;
}

.carousel , .carousel div img{
    width: 90%;
    height: 100%;
}

.carousel div{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
}