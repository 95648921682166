
.darkBody .gurumasterBox{
    
    position: fixed;
    margin: auto;
    bottom: 0px;
    z-index: 7;
    height: 80px;
    /* border: 1px solid  #00b7ff ; */
    left: 4%;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bolder;
}
.gurumasterbody{
    
}