@font-face {
    font-family: ultra;
    src: url('../../../public/Ultra-Regular.ttf');
}

@font-face {
    font-family: Teko-Medium;
    src: url('../../../public/Teko-Medium.ttf');
    font-size: 40px;
}

@font-face {
    font-family: orbitron;
    src: url('../../../public/Orbitron-Bold.ttf');
}

@font-face {
    font-family: Rationale;
    src: url('../../../public/Rationale-Regular.ttf');

}

@font-face {
    font-family: Offside;
    src: url('../../../public/Offside-Regular.ttf');

}

/* @font-face {
    font-family: PlayfairDisplay;
    src: url('../../../public/PlayfairDisplay-Regular.ttf');
    font-size: 12px;
} */


body {
    font-family: teko;
}

h1,
h2,
h3,
h4,
h5 {
    color: #000;
    font-family: Orbitron;
    text-shadow: none;
    font-weight: bolder;
}

h1 {
    font-size: 14px;
}


.homeHeader {
    background-color: rgb(6, 35, 61);
    height: 79 vh;
    background-image: url('../imgz/home/bgFilter.png');
    background-size: 90%;
}



.homeHeader h3 {

    color: rgb(10, 104, 167);
    text-shadow: -1px -1px #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    font-size: 20px;
}

.homeHeader p {
    /* font-size: 40px; */
}

.homeHeader .small_desc {
    color: #cfd1d2;
    font-size: 14px !important;
}



.homeHeader button:hover {
    background-color: #062a47;
    color: #fff;
}

.homeHeader .buttonswrapper {
    position: absolute;
    bottom: 60px;
}

button {
    font-family: Offside !important;
}

.darkButton,
.homeHeader button,
.bizinterest button,
.biz_report button,
.cards button,
.bizinterest_wrapper button,
.blackButton {
    background-color: #000 !important;
    font-size: 14px;
    padding: 18px;
    border-radius: 0%;
    min-width: 150px;
    font-weight: bolder;
    color: #ffffff;
    font-family: Offside !important;

}

.homeHeader button {
    font-size: 16px !important;
    font-weight: normal !important;
}

.homeHeader button {
    font-size: 12px;
    padding: 13px;
}

/*.............................................Business Interest ........................*/
.bizinterest {

    /* background-image: url('../../../src/components/imgz/home/bgBizInterest.png'); */
    background-repeat: no-repeat;
    background-size: cover;
}

.bizinterest .row {
    height: auto;
}



.bizinterest_wrapper .left_part {
    border: 1px solid #00b7ff
}

/*Te below are the media queries for Biz Interest .*/
@media only screen and (max-width: 1100px) {}


.bizinterest_wrapper,

.spacer {
    background-color: #d7eff4;
}

.bizinterest_wrapper h1,
.bizinterest_wrapper h2 {
    text-align: left !important;
    text-transform: capitalize;

}

.bizreportcontainer {
    background-color: #fff;

}

.bizreportcontainer p {
    text-align: justify;
}



/* ......................................... Biz Reports .......................... */

.biz_report .cardTitle {
    font-family: Orbitron;
    font-weight: bolder;
    color: #000 !important;
}

.biz_report h1 {
    text-align: left;
    text-transform: capitalize;
}

.biz_report .cardDescription {

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

/* ..........................................Developer ....................................*/


.dev p {
    text-align: justify;

}

.dev_inner_image {
    background-image: url('../imgz/home/OnlyTheo.JPG');
    min-height: 650px;
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: center;
    background-position-y: 20px;
    background-size: 98%;
}


/*The below media queries are for the Dev Interest layout, the picture changes on small devices, the test is sized, ligned well
 on different portions of the devices (1100, 800, 600, 500) */
@media only screen and (max-width: 1100px) {

    /* #region -----------BIZ INTEREST ---------- */
    .bizinterest {
        background-image: none !important;
    }

    .bizinterest .row {
        height: auto;
    }

    .bizinterest,
    .bizinterest_wrapper {
        padding: 0px !important;
        margin-top: 30px !important;
    }


    .bizinterest,
    .bizinterest_wrapper,
    .bizinterest_wrapper .left_part,
    .bizinterest_wrapper .right_part {
        margin-top: 0px !important;
        /* padding-top: 0px !important; */
    }

    .bizinterest_wrapper .left_part {
        margin-left: 0px !important;

    }

    /* #endregion */

    /* #region ---------DEV ----------- */

    .dev_inner_image {
        background-color: yellow;
    }

    .dev_inner_image {
        background-image: url('../imgz/home/onlyTheoMobile.png');
        min-height: auto;
        background-size: cover;
        background-repeat: no-repeat;
        height: 450px;
    }

    .dev_inner_image .overlay {
        top: 0px;
        width: 100%;
        position: absolute;
        height: 100%;
        background-color: #000;
        opacity: 0.5;
        content: " ";
        display: block;
        z-index: 1;
    }

    .dev_inner_image h1 {
        font-size: 50px;

    }

    .dev_inner_image h1 {
        width: 500px;
        color: #fff;
        z-index: 2;
        position: relative;
        line-height: 3.9rem;

    }

    /* #endregion */



}

@media only screen and (max-width: 800px) {
    .dev_inner_image h1 {
        font-size: 30px;
        line-height: 2.7rem;
        width: 380px;
    }
}

@media only screen and (max-width: 500px) {
    .dev_inner_image h1 {
        font-size: 30px;
        line-height: 2.7rem;
    }

    .dev_inner_image {
        background-image: url('../imgz/home/onlyTheoMobile.png');
        min-height: auto;
        background-size: 100%;
        background-repeat: no-repeat;
        height: 290px;
    }
}

.dev_inner_block {
    width: 40%;
    background-color: #d7eff4;
    opacity: 0.3;
    font-size: 18px;
}


.dev h1 {

    color: #000000;
    font-weight: bold;
    font-size: 50px !important;
}

.dev .card {
    margin-top: 40px;
}

.dev .devCarTitle {
    color: #000000 !important;
    font-size: 30px !important;
    font-weight: bolder;
    /* font-family: Arial, Helvetica, sans-serif */
    font-family: Orbitron;
}


.dev .programmerskills {
    margin-top: 100px !important;

}

.programmerskills h1 {
    font-size: 50px;
    width: 60%;

    padding-left: 20 !important;
}

.programmerskills .smallerText {
    font-size: 20px;
    font-weight: normal !important;
    color: #000;

}

.programmerskills .card {
    background-color: transparent;
    background: transparent;
    border: none !important;
    box-shadow: none !important;
}

.partneranim_bg {
    /* background-color: #e1eaf0; */
    padding: 10px;
}






/* ............................................... Solutiions ................................................... */
.g {
    color: #b500fd;
}


/*----------------------------------------------- Services (changed to mission,)---------------------------------------------- */

.servicesBg p {
    font-size: 13px !important;
    line-height: 1.6rem;
    margin-bottom: 0px !important;
    /*This is to remove the default margin bottom */

}

.servicesBg {
    background-color: #327a89 !important;
    color: #000;

}

.servicesBg h1 {
    color: #000;
    font-weight: bold;

    margin: 40px auto;

}

.servicesBg h3 {
    margin-top: 40px;
    color: #000;
}



.servicesBg p.smaller {
    width: 87%;

}

.leftDesigner {
    background-image: url('../imgz/Designer.png');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 600px;
}



/* ---------------------------------------------dev and Services  ------------------------------------------*/

.dev h1 {
    font-size: 20px;

}

.dev .cardDesc,
.servicesBg p {
    font-weight: bolder !important;


}


.imgSellOnline {
    background-size: 98%;
    background-color: #fff;
    background-position: center;
    background-position-y: 20px;
    background-repeat: no-repeat;
    height: 620px;
    background-image: url('../../components/imgz/home/imgSellOnlineSharp.JPG');
}

/*---------------------------------------End dev services -----------------------------------------------------*/




button {
    font-family: Rationale !important;
}

.list_numbering {
    padding-left: 13px;
    padding-top: 3px;
    width: 40px;
    height: 40px;
    font-size: 12px;
    font-weight: bold;

    background-color: #00b7ff;
    color: #fff !important;

    border-radius: 100%;
    display: inline-block;
    color: #000;

    box-shadow: 0px 0px 3px #000;
    border: 1px solid #fff;

    align-items: center;
    margin: 10px;
    margin-top: 3px;
    margin-bottom: 5px;
}



p {
    font-family: Offside;
    font-size: 14px !important;
    line-height: 2rem;
    text-align: justify;
    /* color: #000; */
}

.nav-brand {
    font-size: 40px !important;
}



/* The below is to animate the cards */

/* src/AnimatedCard.css */
.animated-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full height of the viewport */
}

.animated-card {
    transition: transform 0.5s ease-in-out;
    /* Smooth transition */
}

.animate {
    transform: translateY(-20px);
    /* Move up by 20px */
}

/* Commonn styles all pages */

.bigTitle {}




.contentPartWrapper,
.horizontalCards {
    padding: 20px;
}

.contentPart {
    background-color: #fff;
    padding: 80px;

}

.contentPart h1 {
    margin-top: 60px
}

.contentPart p {
    margin-top: 30px
}

.imgWrapper {
    margin-top: 60px;
}

.cssImage_container {
    padding: 20px;
}

.horizontalCards .cardWrapper {
    padding: 70px;
}

.goalsCard {
    width: 18rem !important;
    padding: 30px !important
}

.goalswrappers h1,
.goalswrappers .title,
.cardWrapper .title {
    font-size: 55px !important;
    text-align: left;
}

.bigView {
    display: block;
}

.smallView {
    display: none;
}

.goalsCard {
    width: 18rem;
    padding: 30px !important
}


.bg_angled {
    padding: 80px;
    padding-left: 80px;
}

.bg_angled p {
    width: 90% !important;
    margin: 20px !important;
}


.systemInADay {
    padding: 20px;
    font-family: Offside;
}

.systemInADay #title {
    font-size: 35px;
}


.container,
.container-fluid {
    padding: ipx !important;
}



.homeHeader h1,
.homeHeader h2,
.homeHeader h3 {
    font-family: ultra !important;
}

.homeHeader h1,
.homeHeader h2 {

    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
    color: rgb(10, 104, 167);
    gap: 1.8rem;
}




/* -------------------new Tutorial home styles ----------------- */

.goalsCardWrapper {
    height: 690px;
}

.newhome .newhome p .newhome h1,
.newhome .btn,
.newhome a {

    font-family: century gothic !important;
}

.newhome p,
p.phome , .changedText .changedText p{
    font-size: 20px !important;
    color: #c3d2d3 !important;
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
}
.newhome p,
p.phome , .changedText p {
    line-height: 2.6rem;    ;

}
.newhome p, .changedText p , .changedText h1, .changedText h5{
    font-family: century gothic !important;
}
.changedText h5{
    font-weight: bolder !important;
    font-size: 24px !important;
    padding-top: 5rem;
    border-top: 1px solid #13a0ff;
    color: #eef1f1 !important;
}
.changedText p {
    line-height: 1.6rem;
    font-size: 15px !important;
    color: #eef1f1 !important;
}
.changedText img{
    width: 100%;
}
.newhome h5 {
    text-transform: capitalize;
    color: #adadad;
    font-family: century gothic !important;
    font-weight: bold;
    text-shadow: -1px -1px #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

}

.newhome .Best_tech_stack li {
    color: #898989;
    ;
}

 
.newhome .Best_tech_stack  .col-md-3  {
   
   margin: 30px;
   border: 1px solid #fff;
   padding: 30px;
}

h1.hhome {

    font-family: century gothic !important;
    background-color: transparent !important;
}

.newhome a {
    border-radius: 30px !important;
}

.newhome a.active {
    background-color: #00b7ff !important;
}

.changedText .changedText p{
    /* color: #000 !important; */
}



@media only screen and (max-width: 1100px) {

    /* ----------  PROGRAMMER SKILLS ---------- */

    .programmerskills * {
        position: relative;
    }

    .programmerskills h1 {
        font-size: 27px !important;
        width: 100% !important;
        text-align: center;
    }

    .programmerskills img {
        width: 100% !important;
    }

}

@media only screen and (max-width: 992px) {
    .homeHeader h1 {
        margin-top: 60px !important;
    }

    .homeHeader .buttonswrapper {
        position: relative;
        bottom: 60px;
    }

    .row {
        margin: 0 auto !important;
    }

    .homeHeader {
        text-align: center;
    }

    .goalsCard {
        width: 100% !important;
        margin-top: 30px !important;
    }


}

@media only screen and (max-width: 767px) {


    .imgSellOnline {
        background-size: 100%;
        background-image: url('../../components/imgz/home/meAndTheoMobile.png');
    }

    .container {
        max-width: 100% !important;
        overflow-x: hidden !important;
    }

    body {
        overflow-x: hidden !important;

    }

    .row {
        margin: 0 auto !important;
    }

    .carousel-container p {
        font-size: 18px !important;
    }



    .homeHeader {
        text-align: center;
    }

    .centeredcard {
        width: 100% !important;

    }

    .dev *,
    .dev .card,
    .dev .e-card,
    .dev .row,
    .dev .cardDesc,
    .dev .container,
    .e-card-content,
    .dev [class^="col"],
    .dev [class^="row"] {
        width: 100% !important;
    }

    .dev .col,
    .dev .row {
        margin: 0px !important;
        padding: 0px !important;
    }

    .homeHeader h1 {
        font-size: 30px !important;
        text-align: center;
    }

    .programmerskills * {
        position: relative;
    }

    .programmerskills h1 {
        font-size: 34px !important;
        width: 100% !important;
        text-align: center;
    }

    .programmerskills img {
        width: 100% !important;
    }

    .imgSellOnline {
        height: 350px !important;
    }


    .goalswrappers h1,
    .cardWrapper,
    .goalswrappers .title,
    .cardWrapper .title {
        font-size: 25px !important;
        margin-top: 30px;
    }


    .goalswrappers h1,
    .cardWrapper,
    .goalswrappers .title,
    .cardWrapper .title {
        font-size: 30px !important;
    }

    .systemInADay #title {
        text-align: center;

    }

}

@media only screen and (max-width: 567px) and (max-width: 900px) {
    .bigView {
        display: none;
    }

    .smallView {
        display: block;
    }
}

@media screen and (max-width: 567px) {

    /* Give some margin on small device on header item */
    .homeHeader h1,
    .homeHeader h2,
    .homeHeader h3,
    .homeHeader p {
        margin: 4rem auto;
        line-height: 1.8rem;
    }

    .dev *,
    .dev .card,
    .dev .row,
    .dev .cardDesc {

        width: 100% !important;
        margin: auto 0px !important;
    }

    .dev [class^="col"],
    .dev .e-card,
    .dev [class^="row"] {
        width: 100% !important;
        margin: auto 0px !important;
        padding: 0px;
    }

    .systemInADay #title {
        text-align: center;
        font-size: 23px !important;
        margin-bottom: 50px;
    }

    .systemInADay {
        padding: 10px !important;

    }


    .bigTitle h1 {
        font-size: 33px !important;
    }

    .bigTitle p {
        width: 100% !important;
        padding-left: 0px !important;
    }

    .nav-brand {
        font-size: 25px !important;
    }

    .servicesBg h1 {
        font-size: 22px;
    }
}

@media screen and (max-width: 465px) {


    .contentPart {
        padding: 10px;
        margin-top: 60px
    }

    .homeHeader {
        height: 100vh;
    }

    .homeHeader h1,
    .homeHeader h2 {
        font-size: 17px !important;
        color: #97a4bc;
        text-shadow: none !important;
    }



    .homeHeader .buttonswrapper .btn {
        margin-top: 30px !important;
        font-size: 12px !important;
        width: auto !important;
    }

    .homeHeader .buttonswrapper .btn:nth-of-type(2) {
        margin-left: 10px !important;
    }

    .contentPartWrapper {
        padding: 6px;
    }

    .contentPartWrapper h1 {
        font-size: 25px;

    }

    .imgSellOnline {
        .imgSellOnline {
            height: 330px !important;
        }
    }

    .imgWrapper {
        margin-top: 20px;
    }

    .centeredcard {
        width: 100% !important;
        padding: 5px !important;
    }

    .dev_inner_image .col {
        padding: 0px !important;
    }

    .cssImage_container {
        padding: 5px;
    }

    .dev_inner_image h1 {
        font-size: 20px;
        margin: 0px;
        width: 200px;
    }

    .horizontalCards .card,
    .horizontalCards .card .row {
        width: 100% !important;
        margin-top: 100px;
    }

    .cardWrapper {
        /*It is used in two locations(files) Solutons.js and TEcnologies.js */
        width: 100% !important;
        padding: 0px !important;
        margin: 0px !important;
        /* padding-top: 40px !important; */

    }


    .horizontalCards .cardWrapper {
        padding: 0px !important;
        margin: 0px !important;
        width: 100% !important;

    }

    .horizontalCards .row,
    .horizontalCards .col {
        width: 100% !important;

    }

    .horizontalCards .row .col {
        padding: 0px;
        margin: 0px;
        width: 100% !important;
    }

    /*-------------TEchnologies---------*/
    .goalswrappers {
        width: 100% !important;
        height: auto !important;
        /* position: relative !important; */
        margin-left: 0px !important;
        margin-right: 0px !important;
        padding: 0px !important;
    }

    .goalswrappers h1,
    .cardWrapper,
    .goalswrappers .title,
    .cardWrapper .title {
        font-size: 25px !important;
        margin-top: 30px;
    }

    .goalsCard {
        width: 100% !important;
        /* margin-top: 100px !important; */
        padding: 0px !important;
        background-color: #000000;
    }

    .goalsCard:last-of-type {
        margin-bottom: 50px;
    }

    .goalsCard:first-of-type {
        margin-top: 50px !important;
    }

    .bigView {
        display: none;
    }

    .smallView {
        display: block;
    }

    .bg_angled {
        padding: 10px !important
    }

    .wrappers {
        padding: 0px !important;

    }


    .nav-brand {
        font-size: 20px !important;
    }

    /* ----Title on Navbar-------- */
    .nav-brand {
        font-size: 14px !important;
    }

    /* headers */
    .newhome h1,
    .newhome .otherfeature .smallHeader,
    h1 {
        font-size: 30px !important;
    }
}

@media only screen and (min-width:767px) and (max-width: 900px) {

    .goalswrappers h1,
    .cardWrapper,
    .goalswrappers .title,
    .cardWrapper .title {
        font-size: 45px !important;
        margin-top: 30px;
    }

    .bigView {
        display: block;
    }

    .smallView {
        display: none;
    }

    .goalsCard {
        width: 100% !important;
        margin-top: 30px !important;
        box-shadow: 0px 0px 2px #000;
    }

    .goalsCardWrapper {
        padding: 0px !important;
        margin: 0px !important;
        width: 100% !important;

    }

}


/* -------------------------------Large devices on .servicesBg {  ----------------------------------------------- */

@media only screen and (min-width:567px) {
    .servicesBg h1 {
        font-size: 25px !important;
    }
}

@media screen and (min-width: 767px) {
    .servicesBg h1 {
        font-size: 32px !important;
    }
}

@media only screen and (min-width: 900px) {
    .servicesBg h1 {
        font-size: 36px !important;
    }

    .serviceWrapper {
        padding: 90px !important;
        padding-top: 10px !important;
    }
}