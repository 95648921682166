/*-----------------------------USER REGISTRATION----------------------------------------*/
.user-registration .userRegistrationWrapper,
.user-registration .userRegistrationWrapper * {
    /* background-color: #f6f8fa; */
    color: #000;
    font-family: Offside;
}

.user-registration .userRegistrationWrapper .boldTitle {
    color: #000 !important;
    font-size: 30px;
    font-family: Orbitron !important;
    margin-top: 60px;
    font-size: 45px !important;
    text-align: center !important;
}

@media only screen and (max-width:765px) {
    .user-registration .userRegistrationWrapper .formWraper .boldTitle {
       font-size: 30px !important;
    }
}

.user-registration h1 {
    color: #000 !important;
    font-family: Orbitron !important;

}


.user-registration .userRegistrationWrapper .formBox {
    margin-top: 60px !important;
}

.user-registration .userRegistrationWrapper .form-row .form-control,
.user-registration .userRegistrationWrapper .form-row .form-select {
    background-color: transparent !important;
    color: #082b46 !important;
}



.user-registration .intro {
    font-family: Offside !important;
}

.user-registration .formWrapper {
    background-color: #fff;
}

.user-registration .imagePart {
    background-image: url('../../imgz/contactusImg.png') !important;
    background-size: 100%;
    background-repeat: no-repeat;
}

/*-----------------------------END OF USER REGISTRATION----------------------------------------*/