

img{
    transition: transform .3s; /* Animation */
    overflow: hidden;
    
    
}
img:hover{
    /* -webkit-transform: scale(1.1); 
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); */
}

.img_box{
    overflow: hidden;
}



 p{
    text-align: justify;
}
.rounded_btn {
    background-color: rgb(255, 85, 0);
    padding: 16px;
    border-radius: 25px;
    font-size: 13px;
    text-decoration: none;
    box-shadow: 0px 0px 2px #000;
    border: #fff 1px solid;
    min-width: 160px;
}
.txt_main{
    font-size: 25px;
}

