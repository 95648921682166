@font-face {
    font-family: Offside;
    src: url('../../../public/Offside-Regular.ttf');

}

.servicesprovidedBg ul,
li.servicesprovidedBg li {
    font-size: 15px;
}

.servicesprovidedBg h4 {
    font-size: 18px !important;

}


.servicesprovidedBg li {
    font-family: Offside;
}

.servicesprovidedBg h1,
.servicesprovidedBg .intro {
    text-align: center;
}

.servicesprovidedBg .intro {
    width: 70%;
    margin: auto;
    font-size: 25px;
    border-bottom: 3px solid #00d0ff;
    padding-bottom: 45px;
}

.servicesprovidedBg {
    background-color: #f6f8fa;
}

.servicesprovidedBg [class^="col"] {
    margin: 3rem auto !important;
    
}

.servicesprovidedBg .gutter {
    background-color: #fff;
    margin: 1.2rem;
    padding: 58px;
    border: 1px solid #94dfff;
    height: calc(100% - 1.5rem);
    border-radius: 8px;
    border-radius: 8px;
    /* box-shadow: 0px 0px 1px #000; */
}

.servicesprovidedBg ul li {
    line-height: 1.5rem;
    margin-top: 20px;
}

.servicesprovidedBg h4 {
    font-weight: bolder;
    margin-bottom: 13px;
}

.servicesprovidedBg b {
    text-align: left !important;
}


.interruptor {
    background-color: #062667;
    color: #fff;
    font-weight: bolder;
    margin-top: 80px;
    margin-bottom: 80px;
}
.interruptor p{
    color: #98aabe;
}
.interruptor h2 {
    color: #fff;
    text-transform: capitalize;
}

@media only screen and (max-width: 992px) {

    .servicesprovidedBg .gutter,
    .col {
        width: 100% !important;
        margin: 5px !important;
    }

    .container {
        width: 95% !important;
    }

    .servicesprovidedBg {

        padding: 0px !important;
        margin: 0px !important;
    }

}

@media only screen and (max-width: 567px) {

    .interruptor h2 {
        font-size: 25px;
    }
}