@font-face {
    /* font-family: Rajdhani-Medium;
    src: url('../../../public/Rajdhani-Medium.ttf') */
}

.dockerBody p{
    font-family: century gothic !important;
}

.dockerBody {
    background-color: #fff !important;
    color: #494949;
    /* font-family: Rajdhani-Medium; */
    /* font-family: teko; */
}

.dockerBody h1 {
    background-color: #131325;
    /* font-family: Rajdhani-Medium !important; */
    /* font-family: teko !important; */
    text-transform: capitalize;
    font-size: 29px !important;
    color: #b6b6b6;
    padding: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

h1:nth-of-type(2),
h1:nth-of-type(3) {
    margin-top: 100px;
}

h2 {
    color: #2e2e2e;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-shadow: none;
    font-weight: bolder;
}

.codeCard {
    background-color: #131325;
    color: #f1f1f2;
    border-radius: 15px;
    margin: 20px auto;
}

.codeTitle {
    background-color: #0c3b4d;
    border-radius: 15px;
    padding: 20px;
    font-weight: bold;
}

[class^="levl"] {
    display: block;
    /* background-color: #082a36; */

}

[class^="levl"],
.codeRes table tr td {
    font-size: 13px;
    font-family: 'Courier New', Courier, monospace !important;
    line-height: 1.5rem;
}

.secondTd {
    padding-left: 15px;
}

.secondTd:first-of-type {
    padding-left: 3px;
}

p.code,
.code * {
    font-size: 13px;
    font-family: 'Courier New', Courier, monospace;
}

p.code {
    position: relative;
    color: #fff;
    background-color: #1b1b27;
    padding: 2rem;
    padding-left: .5rem;
    border-radius: 20px;
    font-weight: bolder;

    /* overflow-x: scroll; */

}

.notSelect {
    margin: 0 1rem;
    color: #cecbcb;
}

.color1 {
    color: #ffa200;
}

.color2 {
    color: aqua;
}

.color3 {
    color: #ff6200;
}

.code button {
    padding: 8px;
    border: none;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    /* Safari and older Chrome */
    -moz-border-radius: 6px;
    /* Firefox */
    position: absolute;
    top: -20px;
    right: -8px;
    border: 1px solid #fff;
    font-weight: bolder;
    font-family: 'Courier New', Courier, monospace !important;
    background-color: #78d1d8;
}

.levl2 {
    margin-left: 15px;
}

.levl3 {
    margin-left: 30px;
}

.levl4 {
    margin-left: 45px;
}

.levl5 {
    margin-left: 60px;
}


.h-80vh {
    height: 80vh;
}

p.newhome {
    margin: 0px !important;
    line-height: normal !important;
}