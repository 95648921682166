.whatsappIcon {
    position: fixed;
    bottom: 4%;
    right: 5px;
    width: 48px;
    height: 48px;
    z-index: 6;
    border: 5px solid #00c3ff;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px #000;
    padding: 6px;
}
.whatsappIcon:hover{
    background-color: #e3f6ff;
}
@media only screen and (max-width: 1000px) {

    .whatsappIcon {
        right: 20px;
        bottom: 5%;
    }
}

