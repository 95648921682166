
  
  .footer {
    margin: 0px;
    
    padding: 1rem;
    background-color: #01141d;
    color: #71ddf0;
    font-weight: bolder;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
  }
  