 
 .boxDraggable{
    width: 200px;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #fff !important;
 }
 a, .btn{
   border: none ;
   /* background-color: transparent; */
   font-weight: bolder;
 }