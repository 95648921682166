.contactusbox {
    /* background-image: url('./images/contactus.jpg'); */
    background-size: 100%;
    position: relative;
    font-weight: bolder;
}

.contactusbox p {
    font-size: 15px;
    color: #cacaca;
}

.contactusbox p,
.contactusbox h1,
.contactusbox h2,
.contactusbox .icon {
    z-index: 2;
    position: relative;

}

.contactusbox h1,
.contactusbox h2 {
    color: #fff !important;
}

.contactusbox::before {
    background-color: #000;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    content: " ";
    opacity: 0.8;
    z-index: 1;
}

.contatuswrapper button {
    background-color: #03162e !important;
    font-size: 14px;
    padding: 18px;
    border-radius: 0%;
    min-width: 150px;
    font-weight: bolder;
    color: #ffffff;
}

.contatuswrapper .form-control {
    /* background-color: #000 !important; */
    height: 43px !important;

    font-weight: bolder;
    color: #000000 !important;
    font-size: 13px !important;
    border: #004d7d 1px solid !important;
    border-radius: 0px !important;
    margin-top: 3px !important;
}

.largeText{
    height: 130px !important;
    resize: none;
    width: 100%;
    padding: 8px;
}
textarea{
    height: 180px !important;
}
.servicesprovidedBg{
    color: #000 !important;
    font-family: Orbitron !important;
}
@media screen and (max-width: 567px) {
    .row {
        display: block !important;
        width: 100% !important;

    }

}


@media screen and (max-width: 990px) {
    .contatuswrapper div {
        width: 100%;
    }

}

@media screen and (min-width: 990px) {
    .contatuswrapper { width: 100%;}
}