.darkBody {
    background-color: #041b2d !important;
    background-color: red;
    color: #8f99a6 !important;
    font-family: century gothic;;
}


.darkBody h1, .darkBody h2, .darkBody h3, .darkBody h4, .darkBody h5,
.darkBody p{
    font-family: century gothic !important;
}

.darkBody,
.darkBody h3,
.darkBody h1,
.darkBody h2,
.cards .desc {
    color: #8f99a6 !important;
    
}

.darkBody .cards .desc {
    text-align: justify;
}



.darkBody .cards h1,
.darkBody .cards h2,
.darkBody .cards h3,
.darkBody .cards h4 {
    margin-top: 2rem !important;
    text-align: center;
}

.darkBody h1 {
    font-size: 30px !important;
    color: #b5c1c4 !important;
}

.darkBody .boldTitle {
    color: #b5c1c4 !important;
}

.darkBody h3 {
    font-size: 20px !important;
    color: #52696e !important;
}

.darkBody h4 {
    font-size: 16px;
    color: #b5c1c4 !important;
}

.darkBody h1,
.darkBody h3 {

    font-weight: bolder;

}

.darkBody .form-control, .darkBody .form-select  {
    background-color: #000 !important;
    height: 53px !important;
    font-weight: bolder;
    color: #00b7ff !important;
    font-size: 13px !important;
    border: #004d7d 1px solid !important;
}

.darkBody .form-control:focus {
    outline: none !important;
    box-shadow: none !important;
}

.darkBody .largeText {
    height: 200px !important;
    resize: none;
}

.darkBody .modalStyles {
    background-color: #031727 !important;

}

.darkBody .buttons {
    /* background-color: #092553; */
    padding: 15px;
    border: #03394f 3px solid !important;
    margin-top: 30px !important;
    box-shadow: inset 0px 0px 2px #000;
    border-radius: 4px !important;
}





a {
    color: #470343;
    text-decoration: none;
}

.heading {
    /* color: '#000' !important; */
    font-size: 25px;
    font-weight: bolder;
    text-transform: uppercase;
}


.cards {
    margin-top: 50px !important;
    border: #00b7ff 1px solid !important;
    background-color: #082b46 !important;
    box-shadow: inset 0px 0px 15px #000 !important;
    color: #879298 !important;
}

.cards * {
    margin: auto !important;
}


.modallargeContent {
    /* background-color: #000; */
}

.closeVariant {
    color: #00a6ff !important;
}




/* ------------------------------------------------------------------Bouncing animation ----------------------------------------------------------------------- */
.ball {
    animation: bounce 10s;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
    animation-iteration-count: infinite;
}

@keyframes bounce {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(0, 40px, 0);
    }
}


            
            

/* Prefix Support */

ball {
    -webkit-animation-name: bounce;
    -webkit-animation-duration: 10s;
    -webkit-animation-direction: alternate;
    -webkit-animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
    -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        -webkit-transform: translate3d(0, 40px, 0);
        transform: translate3d(0, 200px, 0);
    }
}

/* ----------------------------------------End of the bouncing ball --------------------------------------------------- */


.formDetailsWrapper {
    padding-left: 40px;
    border-left: #2c4146 1px solid;

}

.formDetails p {
    margin: 70px auto;
}

.formDetails p:first-child {
    margin-top: 5px !important;
}

.formDetailTitle {
    color: #e2e5e8 !important;
    font-weight: bolder;
}

.formDetails .notes {
    color: #f2f3f5 !important;
    font-weight: normal;
    font-size: 15px !important;
    line-height: 2rem;

}

.devCarTitle h3 {
    color: #f2f3f5 !important;
    font-size: 16px !important;
    margin: 30px auto !important;
}

.darkBody h1,
.darkBody h2,
.darkBody h3,
.darkBody h4 {
    text-shadow: none !important;
}

.btn {
    border: 1px solid #0b6f91 !important;
    background-color: #072539;

    color: #fff;
    padding: 10px 15px;
    /* border: #03394f 3px solid !important; */
    /* margin-top: 30px  !important; */
    box-shadow: inset 0px 0px 6px #000;
    border-radius: 4px !important;
}





/* Special Theme */

h1.specialHeading {
    color: #041b2d !important;
    text-shadow: -1px -1px 0 #0b6f91, 1px -1px 0 #0b6f91, -1px 1px 0 #0b6f91, 1px 1px 0 #0b6f91 !important;
    font-size: 70px !important;
    font-weight: bolder !important;
}


/* Li Data of the dark theme */

.darkBody ul{
        list-style: none;
}
.darkBody li, .darkBody .dataList a{
    font-size: 20px;
    color: #00a6ff;
    font-weight: bolder;
   
    /* line-height: 3rem; */
}
.darkBody .rerunCol{
    text-align: right;
}

.darkBody a.btnAppStatus{
    font-size: 13px;
}


.darkBody li.big{
    padding: 25px;
    border-bottom: 1px solid #384255;
}
.darkBody li.small{
    font-size: 13px;
    color: #8a8a8b;
}

 