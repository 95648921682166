@font-face {
    font-family: teko;
    src: url('../../../public/Teko-Medium.ttf')
}

@font-face {
    font-family: orbitron;
    /* src: url('../../../public/Orbitron-Bold.ttf'); */
    src: url('../../../public/Orbitron-Bold.ttf');
}
@font-face {
    font-family: Offside;
    src: url('../../../public/Offside-Regular.ttf');
    
}
.customBarContainer , .custom-navbar{
    font-family: orbitron;
}



.logo {

    width: 50px;
    height: 40px;
    content: " ";
    background-image: url('../imgz/logo/Logo40.png');
    background-size: 90%;
    /* display: block; */
}

.shaking {
    animation: shake 4s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite;

}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.customBarContainer {
    background-color: #01141d;
    
}

.customBarContainer .col {
    overflow: hidden;
    font-size: 13px;
    font-family: century gothic ;
}


.customBar .col,
.customBar .row {
    margin: 0px;
    padding: 0px;
    background-color: #01141d;
    color: #00b3ff;
    font-family: century gothic ;
}

.customBar {
    height: 70px;
    font-size: 13px;
}


@media only screen and (max-width: 767px) {
    .customBarContainer .col{
        font-size: 14px !important;
    }
}



/* ---------------------------- The below are the events for navbar, we want to sho the nav as we scroll up*/
.navbar {
    transition: top 0.3s;
    position: fixed;
    width: 100%;
    top: 60;
    left: 0;
    background-color: #333;
    color: white;
    padding: 1rem;
  }
  
  .hidden {
    top: -100px; /* Adjust as needed to hide the navbar */
  }
  
  .visible {
    top: 60;
  }