 .aboutus {

     position: relative;
     /* height: 100vh; */
 }
.header{
    background-color:  rgb(3, 135, 251) ;
   
    padding: 50px;

}
.header h1{
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
        color: #fff !important;
        font-size: 50px;
        margin-top: 70px;
        font-weight: bolder;
}
 .aboutus img{
    width: 110%;
 }

.qualities{
    color: #000 !important;
    margin-top: 80px;
}
.card{
    border: none;
}
.cardTitle{
    font-weight: bolder;
}

.qualities desc{
    font-weight: 13px;
    line-height: 1.1rem; color: #000 !important;
}
 @media only screen and (max-width: 750px) {
     .aboutus img {
        width: 100%;
     }
     .header h1{
        margin-top: 10px;
        font-size: medium;
     }

 }