.custom_login{
    background-color: #fff;
    min-height: 330px;
}

.custom_login .p2{
    background-color: #10a1fbec;
}

.loginbg{
    border: 2px solid #10a1fbec;
    /* background-color: #fafeff; */
    /* box-shadow: 0px 0px 3px #000; */
}