.cardSubTitle{
    color: #e0e0e1;
    margin: 1rem auto !important;
    font-weight: bolder;
}
.centeredcard .cardlink{
    color: aqua !important;
    margin: 12px !important;
}
.desc{
    font-size: 13px !important;
    color: #fff !important;
    text-align: left;
}
.desc .row{
    border-bottom: 1px solid #08eee6;
    padding: 5px; 
    color: #d4d4d4 !important;
}