
.technologies{
    font-family: Arial  !important;
    background-color: #000 !important;
}

p{
    font-size: 19px !important;
}

.wrappers{
    
    margin-top: 80px ;
}

.bg-light{
    background-color: #fff !important;
}
.bg_angled{
    /* background-image: url('../imgz/BgAngled.png'); */
    /* background-size: 50%; */
    background-color: #fff;
}


