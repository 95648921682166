.deployPane{
      width: 97% !important;
    margin-left: 47px !important;
}

 

.part{
    position: relative !important;
    z-index: 1 !important;
}
 