.pageModelWrapper {
    width: 97% !important;
    margin-left: 50px;
}

.pageModelWrapper .remove {
    margin-left: 5px;
}

.pageModelWrapper .remove:hover {
    cursor: pointer;
}
.pageModelWrapper .search{
    width: 97% !important;
}
.staticPart,
.staticPart .part {
    max-height: 78vh;
    padding: 1px;
}
.staticPart .part {
    transition: all 0.3s;
    
}

.scrollableContent {
    max-height: 95% !important;
    width: 100%;
    margin-left: 0px !important;
    padding: 6px !important;
    overflow-x: hidden;
}

.staticPart .part,
.staticPart .col {
    position: relative !important;
    z-index: 1 !important;
}

.scrollableContent {
    overflow-y: scroll;
}

.stickyHeader {
    z-index: 1 !important;
}

.paneSwitchswichToggle {

    width: 20px;
    padding: 3px;
    top: 30px !important;
    right: -22px !important;
    font-size: 12px;
    z-index: 2 !important;
    position: absolute !important;
    background-color: #063442;
    color: #fff;
    font-weight: bolder;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    
}

.paneSwitchswichToggle a {
    color: #fff;
}