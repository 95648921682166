.newhome h1{
    background-color: transparent !important;
    font-family: century gothic;
    font-weight: bold;
    font-size: 60px !important;
    color: #eef1f1 !important;
    text-shadow: -1px -1px #eef1f1  , 1px -1px 0 #eef1f1, -1px 1px 0 #eef1f1, 1px 1px 0 #eef1f1 !important;
}
.newhome .otherfeature .smallHeader{
    font-size: 30px !important;
    text-shadow: none !important;
     /* -1px -1px #eef1f1  , 1px -1px 0 #eef1f1, -1px 1px 0 #eef1f1, 1px 1px 0 #eef1f1 !important; */
}


.newhome .titleSubTitle{
    padding: 70px;
    /* background-color: #343c3d; */
}

.newhome .page{
    border: 1px solid #98a9aa !important;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 0px !important;
}
.newhome .page .title{
    padding: 15px;
    font-size: 16px !important;
    text-align: left;
    background-color: #495b5d;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-size: 13px;
}
.newhome .code_section{
    /* border: 1px solid #a1bec1; */
}
.dark{
    background-color: #495b5d !important;

}
.darker, .changedText{
    background-color: #343c3d !important;
}

.otherfeature{
    
}
.singleFeature{
    border: 1px solid #a1bec1 !important;
    border-radius: 20px !important ;
}

@media screen and (max-width: 465px) {
    /* headers */
    .newhome h1, .newhome .otherfeature .smallHeader, h1{
        font-size: 30px !important;
        text-shadow: none !important;
    }
}